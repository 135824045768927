import '@fontsource/roboto/500.css'
import '../css/login.css'
import '../css/SignUpForm.css'
import React, { useState, useEffect } from 'react'
import TextField from '@mui/material/TextField'
import { Stack } from '@mui/material'
import { Button } from '@mui/material'
import { Alert, Chip } from '@mui/material'
import { ArrowBack, VisibilityOff, Visibility, SimCardAlert } from '@mui/icons-material'
import { FormControl, InputLabel, OutlinedInput, CircularProgress } from '@mui/material'
import userRegistration from '../hooks/userRegistration'
import usePhoneValidation from '../hooks/usePhoneValidation'
import SignUpSteps from './SignUpSteps'
import { Checkbox, InputAdornment, IconButton } from '@mui/material'
import { FormControlLabel, Backdrop, Card } from '@mui/material'
import { terms } from './terms'
import Typography from '@mui/material/Typography';
import Fade from '@mui/material/Fade';
import FormHelperText from '@mui/material/FormHelperText';

const liStyle = { color: '#fff' };

export function SignUpForm({ setRegister, setMessage }) {

    const [name, setUserName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState()
    const [cedula, setCedula] = useState("")
    const [role, setRole] = useState()
    const [password, setPassword] = useState()
    const [passwordError, setPasswordError] = useState()
    const [confirmedPassword, setConfirmedPassword] = useState()
    const [acceptTerms, setAcceptTerms] = useState(false)
    const [acceptNotifications, setAcceptNotifications] = useState(false)
    const [inputCode, setInputCode] = useState()
    const [messageDisplayed, setMessageDisplayed] = useState(false)
    const [confirmedPhone, setConfirmedPhone] = useState(false)
    const [confirmPasswordError, setConfirmPasswordError] = useState(false)
    const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] = useState("")

    const [alert, setAlert] = useState()
    const [displayTerms, setDisplayTerms] = useState()
    const [showPassword, setShowPassword] = React.useState(false)
    const [showConfirmedPassword, setShowConfirmedPassword] = React.useState(false)

    const [chipData, setChipData] = React.useState([
        { key: 0, label: 'Empleador', color: 'default', disabled: false },
        { key: 1, label: 'Empleado', color: 'default', disabled: true },
        { key: 2, label: 'Profesional Independiente', color: 'default', disabled: true },
        { key: 3, label: 'Desempleado', color: 'default', disabled: true },
        { key: 4, label: 'Pensionado', color: 'default', disabled: true },
    ])

    const [step, setStep] = useState(1);
    const nextStep = () => {
        setStep(prevStep => prevStep + 1);
    };

    const prevStep = () => {
        setStep(prevStep => prevStep - 1);
    };

    const handleSelection = (data) => () => {
        const chips = chipData
        if (chips[data.key]['color'] === 'primary') {
            chips[data.key]['color'] = 'default'
        } else {
            chips[data.key]['color'] = 'primary'
        }
        let roleSelected = chips.filter((chip) => chip.color === 'primary')
        var roleLabels = []
        roleSelected.forEach(chip => {
            roleLabels.push(
                chip.label
            )
        })
        setRole(roleLabels)
        //setChipData((chips) => chips.filter((chip) => chip.key !== '10'))

    }

    const handleClickShowPassword = () => setShowPassword((show) => !show)

    const handleClickShowConfirmedPassword = () => setShowConfirmedPassword((show) => !show)

    const {
        success,
        message,
        registrateUser,
        isLoading
    } = userRegistration()

    const {
        getCode,
        isLoadingCode,
        confirmedCode,
        messageCode,
        successCode,
    } = usePhoneValidation()

    const handleClickNotification = () => {
        if (acceptNotifications) {
            setAcceptNotifications(false)
        } else {
            setAcceptNotifications(true)
        }

    }

    const handleAcceptTerms = () => {
        setDisplayTerms(false)
        if (!acceptTerms) {
            setAcceptTerms(true)
        }
    }

    const handleAcceptTermsCheckbox = (value) => {
        setAcceptTerms(!value);
    };

    const showTerms = () => {
        setDisplayTerms(true)
    }

    useEffect(() => {
        if (message) {
            displayAlert(message)
        }
    }, [message,success])

    useEffect(() => {
        if (success) {
            setMessage(
                `¡Ya puedes empezar a conversar con Aurora!`
            )
        } 
        if (successCode && !messageDisplayed) {
            setMessageDisplayed(true)
            setInputCode(
                <div>
                    <TextField label="Código de validacion*" onChange={e => validatePhoneCode(e.target.value)} />
                </div>
            )
            displayAlert('Hemos enviado en código de validacion via SMS a tu número celular', 'success')
        }
        if (messageCode && !messageDisplayed) {
            displayAlert(messageCode)
            setMessageDisplayed(true)
        }

        if (confirmedCode && !confirmedPhone) {
            setConfirmedPhone(true)
            setInputCode()
            displayAlert('se ha confirmado tu número de teléfono celular', 'success')
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setRegister, success, message, role, acceptTerms, setMessage, setPhone, successCode, getCode, setInputCode, isLoadingCode])

    let PersonalInfo = <React.Fragment>
       <div className="form-container">
    <div className="form-group">
        <InputLabel>Nombre Completo:</InputLabel>
        <TextField style={{ marginTop: 10 }} value={name} onChange={handleNameChange} autoComplete="off" />
    </div>
    <div className="form-group">
        <InputLabel>Email:</InputLabel>
        <TextField style={{ marginTop: 10 }} value={email ? email.value : ''} onChange={e => validateEmail(e.target.value)} autoComplete="off" />
    </div>
    <div className="form-group">
        <InputLabel>Cédula:</InputLabel>
        <TextField style={{ marginTop: 10 }} value={cedula ? cedula.value : ''} onChange={e => validateCedula(e.target.value)} autoComplete="off" />
    </div>
    <div className="form-group">
        <InputLabel>Celular:</InputLabel>
        <TextField style={{ marginTop: 10 }} color={phone ? phone.color : ''} value={phone ? phone.value : ''} onChange={e => validatePhone(e.target.value)} autoComplete="phone" />
    </div>
    {inputCode}
    <div className="form-group">
        <InputLabel htmlFor="outlined-adornment-password" className='pass-label'>Contraseña:</InputLabel>
        <FormControl sx={{ m: 1 }} variant="outlined" className='custom-border'>
            <OutlinedInput
                value={password}
                onChange={(e) => handlePassword(e.target.value)}
                id="password"
                className='password'
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                }
                autoComplete="new-password"
            />
            {passwordError && (
                <FormHelperText error={true}>{passwordError}</FormHelperText>
            )}
        </FormControl>
    </div>
    <div className="form-group">
        <InputLabel htmlFor="outlined-adornment-password" className='pass-label'>Confirmar Contraseña:</InputLabel>
        <FormControl sx={{ m: 1 }} variant="outlined" className='custom-border'>
            <OutlinedInput
                onChange={(e) => handleConfirmPassword(e.target.value)}
                autoComplete="confirm-new-password"
                value={confirmedPassword ? confirmedPassword.value : ''}
                id="confirmedPassword"
                className='password'
                color={confirmedPassword ? confirmedPassword.color : ''}
                error={confirmPasswordError}
                helperText={confirmPasswordErrorMessage}
                type={showConfirmedPassword ? 'text' : 'password'}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowConfirmedPassword}
                            edge="end"
                        >
                            {showConfirmedPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                }
            />
            {confirmPasswordError && (
                <FormHelperText error={true}>{confirmPasswordErrorMessage}</FormHelperText>
            )}
        </FormControl>
    </div>
    {password && <div>
        <p style={{ marginBottom: 0, color: '#fff' }}>La Contraseña debe tener: </p>
        <ul>
            <li style={liStyle}>Al menos 8 caracteres</li>
            <li style={liStyle}>Al menos un caracter en minuscula</li>
            <li style={liStyle}>Al menos un caracter en mayuscula</li>
            <li style={liStyle}>Al menos un número o un caracter especial</li>
        </ul>
    </div>}


    </div>
    <FormControlLabel control={<Checkbox className='check notification' onClick={() => handleClickNotification()} />} label="Deseo recibir notificaciones" />
    <FormControl style={{ display: "flex", flexDirection: "row", alignItems: "center",  marginLeft: '-12px' }}>
        <Checkbox
            className='check terms-cond'
            checked={acceptTerms}
            onClick={() => {
                handleAcceptTermsCheckbox(acceptTerms)
            }}
        />
        <Fade in={true} timeout={2000}>
            <Typography className='terms-cond-label' variant="body1" onClick={(e) => showTerms()}>Acepto los <span>términos y condiciones de Aurora</span></Typography >
        </Fade>
    </FormControl>

            <Button className="login-btn" variant="contained" onClick={() => register()}>Enviar</Button>

            
            <p style={{
                color:"#fff",
                fontSize:14,
                textDecoration: "underline"
            }}>Powered by <a style={{
                marginTop:5,
                fontSize:14,
                color: "#fff",
                fontWeight: "bold"
            }} href="https://www.soylisa.com/">Lisa</a> </p>
{/* <p style='font-size:12px; color:gray; text-align:left;'>Powered by <a href='https://soylisa.com' style='text-decoration:none; color:blue;'>Lisa</a></p */}
 
    </React.Fragment >





    let steps = [
        {
            'label': 'Crea tu cuenta',
            'description': 'Con esta información, Aurora podrá darte una atención más personalizada',
            'element': PersonalInfo
        }
    ]

    const card = <div style={{ padding: '6%' }}>
        <h3>Términos y Condiciones</h3>
        <div style={{ marginTop: '20px', height: '38vh', overflow: 'scroll' }}>
            {terms}
        </div>
        <Button style={{ marginTop: '20px' }} onClick={() => handleAcceptTerms()}>Aceptar</Button>
    </div>

    return (
        <React.Fragment >
            <Backdrop
                sx={{ color: '#0000ab', zIndex: 100000 }}
                open={isLoading || isLoadingCode}
            >
                <CircularProgress sx={{ color: "white", fontSize: 80 }} />
            </Backdrop>
            <Backdrop
                style={{ zIndex: 10 }}
                open={displayTerms}
            >
                <Chip className="close-terms" label={<span>X</span >} variant="outlined" onClick={() => setDisplayTerms(false)} style={{ zIndex: 10000 }}> </Chip>
                <Card className='terms' style={{ marginTop: '30%', height: '65%', width: '90%' }}
                    variant="outlined">
                    {card}
                </Card>
            </Backdrop>
            {/* <Button className="back-btn" variant="contained" onClick={() => back()}> <ArrowBack /> Salir </Button> */}
            {alert}
            <div className='form-signup' >
                <SignUpSteps steps={steps} className='step-container' />
            </div>

        </React.Fragment >
    )

    function register() {
        if (!confirmedPhone) {
            displayAlert('debes validar tu número de  teléfono')
            return
        }

        let validatedInputs = (phone ? validatePhone(phone.value) : false) && (confirmedPassword ? handleConfirmPassword(confirmedPassword.value) : false)
        if (validateInput(name) && validateInput(password) && validateInput(phone) && validateInput(acceptTerms) && validateInput(confirmedPassword.value) && validatedInputs) {
            registrateUser(name, phone.value, confirmedPassword.value, terms, acceptNotifications, email.value, cedula.value)
        }
    }

    function back() {
        setRegister(false)
    }

    function validatePasswordWithRequirements(password) {
        const lengthCriteria = password.length >= 8;
        const lowercaseCriteria = /[a-z]/.test(password);
        const uppercaseCriteria = /[A-Z]/.test(password);
        const numberCriteria = /[0-9]/.test(password);
        const specialCharCriteria = /[!@#$%^&*]/.test(password);

        if (!lengthCriteria) {
            return 'La contraseña debe tener al menos 8 caracteres.';
        } else if (!lowercaseCriteria) {
            return 'La contraseña debe tener al menos un caracter en minuscula.';
        } else if (!uppercaseCriteria) {
            return 'La contraseña debe tener al menos un caracter en mayuscula.';
        } else if (!specialCharCriteria) {
            return 'La contraseña debe tener al menos un caracter especial.';
        } else if (!numberCriteria) {
            return 'La contraseña debe tener al menos un digito numerico.';
        }

        return '';
    };

    function handlePassword(value) {
        let message = validatePasswordWithRequirements(value)
        if (message) {
            setPasswordError(message)
            setPassword(value)
        } else {
            setPasswordError()
            setPassword(value)
        }

        if (confirmedPassword) {
            if (password !== confirmedPassword) {
                displayAlert("Deben coincidir los valores en la contraseña");
            }
        }
    }


    function handleConfirmPassword(value) {
        const isValid = password === value;
        setConfirmedPassword({ value, valid: isValid, color: isValid ? 'success' : 'error' });
        setConfirmPasswordError(!isValid);
        setConfirmPasswordErrorMessage(isValid ? "" : "Deben coincidir los valores en la contraseña");

        if (isValid) {
            setAlert();
        } else {
            displayAlert("Deben coincidir los valores en la contraseña");
        }

        return isValid;
    }

    function validateCedula(value) {
        // eslint-disable-next-line
        const re = /^[0-9]*$/
        var cedulaNum = re.exec(value)

        if (cedulaNum) {
             cedulaNum = cedulaNum[0]
        } else {
            return false
        }

        var validate = cedulaNum.length === 11 ? true : false
        if (!validate) {
            displayAlert("El número de cédula debe contener 11 digitos")
            setCedula({ 'value': cedulaNum, 'valid': false, 'color': 'error' })
            return false
        } else if (validate) {
            setCedula({ 'value': cedulaNum, 'valid': true, 'color': 'success' })
            setAlert()
            return true
        }

    }

    function validateEmail(email) {

        // eslint-disable-next-line
        const re = /^(([^<>()[\]\\.,:\s@\"]+(\.[^<>()[\]\\.,:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        var validate = re.test(email)
        if (!validate) {
            displayAlert("No es una dirección de email válida ejemplo: ejemplo@ejemplo.com")
            setEmail({ 'value': email, 'valid': false, 'color': 'error' })
            return false
        } else {
            setEmail({ 'value': email, 'valid': true, 'color': 'success' })
            setAlert()
            return true
        }

    }

    function validatePhone(value) {
        setConfirmedPhone(false);

        // Only allow numbers and check max length of 10 digits
        const re = /^[0-9]*$/;

        if (!re.test(value)) {
            displayAlert("El número telefónico debe contener solo números");
            return false;
        }

        // Limit the value to 10 digits
        const phoneNum = value.slice(0, 10);

        const isValid = phoneNum.length === 10;

        if (!isValid) {
            displayAlert("El número telefónico debe contener 10 dígitos");
            setPhone({ 'value': phoneNum, 'valid': false, 'color': 'error' });
            return false;
        } else {
            setPhone({ 'value': phoneNum, 'valid': true, 'color': 'success' });
            setAlert(); // Clear any previous alert
            getCode(phoneNum, null);
            setMessageDisplayed(false);
            return true;
        }
    }
    function validatePhoneCode(value) {

        var validate = value.length === 4 ? true : false
        var code = value
        if (validate) {
            setAlert()
            getCode(phone.value, code)
            setMessageDisplayed(false)
            return true
        }

    }

    function validateInput(value) {
        if (value !== '' && value !== null && value !== undefined && value !== false) {
            return true
        }
        displayAlert('Los campos con * son requeridos')
        return false
    }

    function displayAlert(message, type = 'error') {
        if (!alert && type === 'error') {
            setAlert(<Alert className='alert' severity="error">{message}</Alert>)
            setTimeout(() => {
                setAlert()
            }, 5000)
        }
        if (!alert && type === 'success') {
            setAlert(<Alert className='alert' severity="success">{message}</Alert>)
            setTimeout(() => {
                setAlert()
            }, 3000)
        }
    }


    function handleNameChange(event) {
        const name = event.target.value;
        // Only set the state if the input value contains only letters and spaces, or is empty
        if (/^[a-zA-Z\s]*$/.test(name)) {
            setUserName(name);
        }
    }
    


}



