import '../css/App.css';
import '../css/accountState.css';
import React from 'react';
import { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import {SignUpForm} from './SignUpForm';
import { datadogRum } from '@datadog/browser-rum';
import Logo from '../media/logo-white-new.png';
import { Button, Stack } from '@mui/material/'
import { getSessionValue } from "../services/organizations/SessionManager";

document.addEventListener('contextmenu', event => event.preventDefault());

export function App() {

    const [goIn, setGoIn] = useState()

    function userInteractionHandler() {
        setGoIn(true)
    }

    useEffect(() => {
        let stored_token = getSessionValue("token")

        if (stored_token && window.location.href === "/") {
            window.location.href = `/?url_token=${stored_token}`
        }
    }, [])


    return (
        <React.Fragment>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<SignUpForm />} />
            </Routes>
        </BrowserRouter>
    </React.Fragment>
    );

}

if (process.env.REACT_APP_ENV !== 'development') {
    datadogRum.init({
        applicationId: 'f3f0213f-729e-43ad-be76-64149843dd71',
        clientToken: 'pub48a1546464136811991fde5e364cda68',
        site: 'datadoghq.com',
        service: 'lisa-frontend',
        env: process.env.REACT_APP_ENV,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
    })

    datadogRum.startSessionReplayRecording();
};

